import React, { useEffect, useState } from "react"
import { Widget, addResponseMessage, toggleInputDisabled } from 'react-chat-widget';
import Client from "@twilio/conversations";
import 'react-chat-widget/lib/styles.css';
import "./ChatWidget.css";
import { TwilsockError } from 'twilsock';

function randomValue() {
    return 1000 + Math.floor(Math.random() * 9000);
}

const chatbotName = '211 Maryland';

const fetchToken = async (derivedUsername: string | undefined) => {
    const response = await fetch("https://intake-9308.twil.io/access_token", {
        method: "POST",
        mode: "cors",
        headers: {
            'Accept': "application/json",
            'Content-Type': "application/json"
        },
        body: JSON.stringify({
            "identity": derivedUsername
        })
    })
    let localToken = (await response.json());

    console.log("Received token: " + JSON.stringify(localToken));
    return localToken.token;
};

const ChatWidget: React.FC<{}> = () => {
    const [token, setToken] = useState<string>();
    const [derivedUsername, setDerivedUsername] = useState<string>();
    const [client, setClient] = useState<Client>();
    const [conversation, setConversation] = useState<any>();

    useEffect(() => {
        console.log("Generating Username");
        let user = localStorage.getItem('username');

        if (user !== null) {
            setDerivedUsername(user);
        } else {
            let suffix = randomValue();
            let username = 'intake_user';
            setDerivedUsername(`${username}-${suffix}`);
            localStorage.setItem('username', `${username}-${suffix}`);
        }
    }, [derivedUsername]);

    useEffect(() => {
        toggleInputDisabled();
    }, []);

    useEffect(() => {
        if (!token && derivedUsername) {
            fetchToken(derivedUsername)
                .then(token => setToken(token))
                .catch((error: Error) => {
                console.error(error.message);
            });
        }
    }, [derivedUsername, token]);

    useEffect(() => {
        const welcomeText = `Would you like to sign up for 211 Health Check? Type “Enroll” to get started. It’s a free 
            and confidential weekly mental health phone support program. Connect with a warm, caring professionally 
            trained mental health specialist. For more information, visit: 
            [https://211md.org/healthcheck](https://211md.org/healthcheck)`;

        const createClient = async () => {
            if (token) {
                const newClient = await Client.create(token);
                addResponseMessage(`**${chatbotName}**: ${welcomeText}`);
                toggleInputDisabled();
                setClient(newClient);
            }
        }
        createClient();
    }, [token]);

    const createConversation = async () => {
        const newConversation = await client?.createConversation();

        if (newConversation) {

            newConversation.on("updated", (update: any) => {
                // Fires when any attribute of a conversation is changed

                // update reasons is an array of strings that describes the reason for a conversation update
                // http://media.twiliocdn.com/sdk/js/conversations/releases/1.2.3/docs/Conversation.html#UpdateReason
                for (const updateReason of update.updateReasons as Array<String>) {
                    switch (updateReason) {
                        case 'state':
                            if (update.conversation.state.current === 'inactive') {
                                addResponseMessage("This conversation is at risk of being closed due to inactivity.")
                            }
                            else if (update.conversation.state.current === 'closed') {
                                addResponseMessage("The conversation ended because of inactivity. You have been disconnected.");
                                addResponseMessage(`**${chatbotName}**: To start a new enrollment, type "begin"`);
                                setConversation(null);
                            }
                            break;
                        case 'friendlyName':
                            // future use: change the header to "Agent Chat" or something
                            break;
                        case 'attributes':
                            // future use: inject conversation.attributes with Taskrouter Task SID to monitor queue position
                            break;
                        default:
                            break;
                    }
                }
            });

            await newConversation.join();

            newConversation.on("messageAdded", (message: any) => {
                // friendlyName comes from flex, author comes from chat-integration-bot
                const messageAuthor = message.attributes.friendlyName || message.author;

                if (messageAuthor === newConversation?.sid) {
                    addResponseMessage(`**${chatbotName}**: ${message.body}`)
                }
                else if (messageAuthor !== derivedUsername) {
                    addResponseMessage(`**${messageAuthor}**: ${message.body}`)
                }
                if (message.attributes.disconnect && message.attributes.disconnect === true) {
                    addResponseMessage(`**${chatbotName}**: To start a new enrollment, type "begin"`);
                    setConversation(null);
                }

            })
            setConversation(newConversation)
            return newConversation;
        }
    };

    const handleNewUserMessage = (message: string) => {
        try {
            if (conversation) {
                conversation.sendMessage(message);
            }
            else {
                if (message.toLowerCase() !== "enroll") {
                    addResponseMessage(`**${chatbotName}** I'm sorry, I didn't understand. To begin an enrollment, please type "enroll"`);
                }
                else {
                    try {
                        createConversation().then((convo) => convo?.sendMessage(message));
                    } catch(err) {
                        if (err instanceof TwilsockError) {
                            fetchToken(derivedUsername)
                                .then(token => {
                                    setToken(token);
                                    createConversation().then((convo) => convo?.sendMessage(message));
                                })
                                .catch((error: Error) => {
                                    console.error(error.message);
                                });
                        }
                    }
                }
            }
        } catch (error) {

        }
    };

    return (
        <Widget
            title="211 Health Check"
            subtitle=""
            handleNewUserMessage={handleNewUserMessage}
            resizable={false}
        />
    )

}

export default ChatWidget;